/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
// @mui material components

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';
import Divider from "@mui/material/Divider";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import Icon from "@mui/material/Icon";
import { Get, Del, Post, Put } from "../../tools/Helper";


function FichasForm({ editar, data, setShow, getLista }) {

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState({ status: null, message: "" });
    const [formData, setFormData] = useState(data);
    const [filePickerId, setFilePickerId] = useState("");

  const inputRef = useRef(null);

    const modifySheet = (sheet, name) =>{
        setFormData({...formData, ficha:sheet, fichaName: name});
        if(sheet === null){
          const randomString = Math.random().toString(10);
          setFilePickerId(randomString);
        }
    }

    const handleClick = () => {
        // open file input box on click of other element
        inputRef.current.click();
    };

    const handleFileChange = (event) => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onload = function () {
            modifySheet(reader.result, event.target.files[0].name);
        };
    }

    useEffect(() => {
        setFormData(data);
    }, [data, setFormData]);

    const handleChange = (e, name = "", value = "") =>{
        if(name !== ""){
          setFormData({...formData, tipo:e[name], tipoArr: e});
        }else{
          setFormData({...formData, [e.target.name]:e.target.value});
        }
    }

    const doRequest = async e =>{
        setStatus({status: null, message: ""});
        setLoading(true);
        setTimeout(async () => {
            const tempData = formData;
            const request = await Put(`fichas/${formData.id_ficha}`, formData);

            if(request.status === "success"){
                await getLista();
                setShow(true);
            if(editar){
                setFormData(tempData);
            }
            }

            setStatus(request);
            setLoading(false);
        }, 1000);
    }

    const verifyError = (name) =>{
        if("errors" in status){
          if(name in status.errors){
            return true;
          }
        }
    
        return false;
    }
    
      const getError = (name) =>{
        if("errors" in status){
          if(name in status.errors){
            return status.errors[name][0];
          }
        }
    
        return "";
    }

    return (
            <MDBox pt={6} pb={3}>
                        <Card>
                            <MDBox 
                                p={3}
                                className="headerFormPymn"
                            >
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <MDTypography variant="h4" display="inline" mr="auto" color="white">
                                            Actualizar Ficha Técnica
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                <MDBox px={3}>
                                    {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                                        <CircularProgress size="10rem" />
                                        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                                    </MDBox>
                                        :
                                        <MDBox>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={4} sm={6}>
                                                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                                    CLAVE:&nbsp;
                                                    </MDTypography>
                                                    <MDTypography fontSize="16" variant="string">
                                                    {(data.servicio !== undefined) ? data.servicio?.clave : ''}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sm={6}>
                                                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                                    UNIDAD:&nbsp;
                                                    </MDTypography>
                                                    <MDTypography fontSize="16" variant="string">
                                                    {(data.servicio !== undefined) ? data.servicio?.nombre_unidad : ''}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={4} sm={6}>
                                                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                                    DEPARTAMENTO:&nbsp;
                                                    </MDTypography>
                                                    <MDTypography fontSize="16" variant="string">
                                                    {(data.servicio !== undefined) ? data.servicio?.nombre_dept : ''}
                                                    </MDTypography>
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                                    DESCRIPCIÓN:&nbsp;
                                                    </MDTypography>
                                                    <MDTypography fontSize="16" variant="string">
                                                    {(data.servicio !== undefined) ? data.servicio?.descripcion : ''}
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                            <Divider className="fullWidth" />
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} lg={2} mt="auto">
                                                    {
                                                    formData.ficha ?
                                                    <MDButton variant="gradient" color="error"
                                                        onClick={()=>modifySheet(null)} fullWidth>
                                                        Eliminar Ficha
                                                    </MDButton>
                                                    :
                                                    <MDButton variant="gradient" color="info"
                                                        onClick={()=>handleClick()} fullWidth>
                                                        Añadir Ficha
                                                    </MDButton>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} lg={2} mt="auto">                                                    
                                                    <input
                                                        type="file"
                                                        name="myImage"
                                                        style={{display:'none'}}
                                                        key={filePickerId || ''}
                                                        ref={inputRef}
                                                        onChange={handleFileChange}
                                                    />
                                                    {formData.ficha ?
                                                        <fragment>
                                                            <MDTypography variant="h6" display="inline" fontWeight="light">
                                                            {formData.fichaName}
                                                            </MDTypography>                                                            
                                                        </fragment>
                                                        : <fragment>
                                                            <MDTypography variant="h6" display="inline" fontWeight="light">
                                                            Ningún archivo seleccionado
                                                            </MDTypography>
                                                        </fragment>
                                                        }
                                                </Grid>
                                                <Grid item xs={12} lg={8} mt="auto">
                                                    <MDInput type="text" label="Descripción" multiline rows={1} name="descripcion"
                                                        error={verifyError("descripcion")} errorMsg={getError("descripcion")}
                                                        value={formData.descripcion} onChange={event => handleChange(event)} fullWidth />
                                                </Grid>
                                                <Grid item md={4} sm={12}>
                                                &nbsp;
                                                </Grid>
                                                <Grid item md={2} sm={12} pb={3}>
                                                    <MDButton disabled={loading}
                                                        className="btnPaymun"
                                                        onClick={() => doRequest()} fullWidth >
                                                        <Icon fontSize="small">done</Icon>
                                                        &nbsp;Actualizar
                                                        
                                                    </MDButton>
                                                </Grid>
                                                <Grid item md={2} sm={12}>
                                                <MDButton 
                                                    onClick={() => setShow(true)} 
                                                    className="btnBackPaymun" fullWidth>
                                                    <Icon fontSize="small">arrow_back</Icon>
                                                    &nbsp;Regresar
                                                </MDButton>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    }
                                </MDBox>
                            </MDBox>
                        </Card>

            </MDBox>
    );
}
  // Typechecking props for the ProfileInfoCard
  FichasForm.propTypes = {    
    editar: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
    getLista: PropTypes.func.isRequired,  
    setShow: PropTypes.func.isRequired,
  };

export default FichasForm;