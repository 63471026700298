/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef  } from "react";

import * as React from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput  from "components/MDInput";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import { Post, Put, Del, Get } from "../../tools/Helper";
import Message from "../../tools/Message";

function ProveedoresForm({ editar, data, setShow, getLista }) {

  const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });  
    
  /*
  const TextMaskPhoneNumber = React.forwardRef(function *(props, ref) {
    yield;
  // const TextMaskPhoneNumber = React.forwardRef = function TextMaskPhoneNumber(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  
  TextMaskPhoneNumber.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };    



  TextMaskPhoneNumber = React.forwardRef = function TextMaskPhoneNumber(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  };
  */   
 
  const getClaveProv = async e =>{
    
    if (!editar) {
      setStatus({status: null, message: ""});
      setLoading(true);
  
      const request = await Get('getClaveProv');      
            
      if(request.status === "success"){   
        setFormData({ ...data, 'clave': (request.parametro.valor + request.total) });         
      }else{
        setStatus(request);
      }      
              
      setLoading(false);
    }
    
  }


  useEffect(() => {
    setFormData(data);
    setStatus({ status: null, message: "" });

    getClaveProv();
  }, [data, setFormData]);

  const handleChange = (e, name = "", value = "") => {
    
    if (e.target.name === 'telefono' && e.target.value.split("").length > 10) {      
      e.target.value = e.target.value.slice(0, 10)      
    }
    
    if (name === "") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [value]: e[value], [name]: e });
    }

    if ("errors" in status) {
      const verifyName = name === "" ? e.target.name : name;
      if (verifyName in status.errors) {
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const doRequest = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = editar ? await Put(`proveedores/${formData.id_proveedor}`, formData) : await Post('proveedores', formData);

    if (request.status === "success") {
      await getLista();
      setShow(true);      
    }
    else if (request.status === 'error'){
      setStatus(request);
    } 
    
    setLoading(false);

  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  return (
    <MDBox pb={3}>
      <Card mt={4}>
        <MDBox 
          p={3}          
          className="headerFormPymn"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                {editar ? "Actualizar" : "Agregar"} Proveedor
              </MDTypography>
            </Grid>           
          </Grid>
        </MDBox>

        <MDBox p={3} >
          <Message status={status} />
          <Grid container spacing={2}>
            {loading ?
              <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                <CircularProgress size="6.66rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </Grid>
              : <>
                <Grid item md={2} xs={12} >
                  <MDInput type="text" label="* Clave" name="clave"
                    error={verifyError("clave")} errorMsg={getError("clave")}
                    value={formData.clave} onChange={event => handleChange(event)}                   
                    inputProps={{ maxLength: 7, readOnly: true,}}
                    fullWidth />
                </Grid>
                <Grid item md={10} xs={12} mt="auto">
                  <MDInput type="text" label="* Empresa" name="empresa"
                    error={verifyError("empresa")} errorMsg={getError("empresa")}
                    inputProps={{ maxLength: 100, }}
                    value={formData.empresa} onChange={event => handleChange(event)} fullWidth />
                </Grid>

                <Grid item md={4} xs={12}>
                  <MDInput type="text" label="* Nombre del contacto" name="contacto"
                    error={verifyError("contacto")} errorMsg={getError("contacto")}
                    value={formData.contacto} onChange={event => handleChange(event)}
                    inputProps={{ maxLength: 100, }}
                    fullWidth />
                </Grid>

                <Grid item md={4} xs={12} >
                  <MDInput type="text" label="* Correo" name="correo"
                    error={verifyError("correo")} errorMsg={getError("correo")}
                    value={formData.correo} onChange={event => handleChange(event)}
                    inputProps={{ maxLength: 25, }}
                    fullWidth />
                </Grid>

                <Grid item md={4} xs={12} >
                  <MDInput 
                    type="number" label="* Teléfono" name="telefono"                    
                    error={verifyError("telefono")} errorMsg={getError("telefono")}
                    value={formData.telefono} onChange={event => handleChange(event)}                    
                    inputProps={{ max: "999", min: "1",  maxLength: "10", }}
                    // inputComponent={TextMaskPhoneNumber}
                    fullWidth />
                </Grid>

                <Grid item md={4} xs={12}>
                  &nbsp;
                </Grid>
                <Grid item md={2} xs={12}>
                  <MDButton disabled={loading}
                    className="btnPaymun"
                    onClick={() => doRequest()} fullWidth >
                    <Icon fontSize="small">done</Icon>
                    &nbsp;{editar ? "Actualizar" : "Agregar"}
                    
                  </MDButton>
                </Grid>

                <Grid item md={2} xs={12}>
                  <MDButton 
                    onClick={() => setShow(true)} 
                    className="btnBackPaymun" fullWidth>
                    <Icon fontSize="small">arrow_back</Icon>
                    &nbsp;Regresar
                  </MDButton>
                </Grid>
              </>
            }
          </Grid>
        </MDBox>
        
      </Card>
      
    </MDBox>
  );
}

ProveedoresForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default ProveedoresForm;