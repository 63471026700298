/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';

import { Link } from "react-router-dom";

import Icon from "@mui/material/Icon";

import ServicioForm from "./ServicioForm";
import { Get } from "../../tools/Helper";
import Message from "../../tools/Message";
import { parseCurrency } from '../../tools/utils';

function Servicio() {

  const emptyForm = {
    clave: '',
    clave_prod_sat: '',
    descripcion: '',
    unidad: [],
    departamento: [],
    p_unit: 0,
    proveedores: '',
    editar_descripcion: false,
    precioUnitario: 0,
    porcentajeUtilidad: 0,
    utilidad: 0,
    precioSinIva: 0,
    costoTotal: 0,
  };


  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  // const [selectedData, setSelectedData] = useState(emptyForm);
  const [tipoServicios, setTipoServicios] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [status, setStatus] = useState({status: null, message: ""});
  const [unidadesInsumos, setUnidadesInsumos] = useState([]);
  const [triggerEdit, setTriggerEdit] = useState(false);


  const editarButton = (row) => {    
    setSelectedData(row);
    setShow(false);
    setEditar(true);
    setTriggerEdit(true);
  }

  const crearButton = () => {
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
    setTriggerEdit(false);
  }

  const editButton = (row) => <MDButton variant="gradient"
    color="info" iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton>;

  const questionButton = (id) => <Link to={`/catalogos/servicios/${id}/acciones`}>
    <MDButton variant="gradient" color="info" iconOnly>
      <Icon>check_box</Icon>
      </MDButton>
    </Link>;

  const showSheets = (id) => <Link to={`/catalogos/servicios/${id}/fichas`}>
      <MDButton variant="gradient" color="info" iconOnly>
      <Icon>upload_file</Icon>
      </MDButton>
    </Link>;

  const getPrice = (serv) => {
    
    let precio = 0;
    let porcUtil = 0;

    serv.insumos.forEach(insumo => {
      precio += parseFloat(insumo.importe);  
      porcUtil = insumo.porcentaje_utilidad;  
    });
    
    let total = parseFloat(precio) + (parseFloat(precio)*porcUtil/100);
    total *= 1.16;

    return parseCurrency(total);

  }

  const columns = [
    {
      name: 'Clave',
      selector: row => row.clave,
      grow: 1,
      sortable: true,
    },  
    {
      name: 'Descripción',
      selector: row => row.descripcion,
      wrap: true,
      grow: 5,
      sortable: true,
    },
    {
      name: 'Unidad',
      selector: row => row.unidad.nombre,
      grow: 1,
    },
    {
      name: 'Precio',      
      selector: row => getPrice(row),
      grow: 1,
    },
    {
        name: '',
        selector: row => editButton(row),
        grow:0,
        compact: true,
    },
    {
        name: '',
        selector: row => showSheets(row.id_servicio),
        grow:0,
        compact: true,
    },
    {
        name: '',
        selector: row => questionButton(row.id_servicio),
        grow:0,
        compact: true,
    },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Get('servicios');

    if (request.status === "success") {
      setData(request.data);
      setTipoServicios(request.tipos);
      setDepartamentos(request.departamentos);
      setResponsables(request.responsables);
      setUnidadesInsumos(request.unidadesInsumos);
      setStatus({ status: request.status, message: "Se ha creado exitosamente." });
    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setSelectedData(emptyForm);
    setLoading(false);
  }

  useEffect(() => {
    getData();

    setStatus({status: null, message: ""});
  }, []);

  const tableData = {
    columns,
    data,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                p={3}
                className="headerFormPymn"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Catálogo Servicios
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton className="btnPaymun btnAdd"
                      onClick={() => crearButton()}
                      disabled={loading}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" />
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                    :
                    <MDBox>
                      <DataTableExtensions
                        {...tableData}
                        export={false}
                        print={false}
                        filterPlaceholder="Buscar..."
                      >
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'Servicios por página:',
                            rangeSeparatorText: 'de',
                            noRowsPerPage: false,
                            selectAllRowsItem: true,
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<MDBox py={5}>No se han encontrado servicios.</MDBox>}
                        />
                      </DataTableExtensions>
                    </MDBox>
                  }

                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>
        <Fade in={!show} timeout={800}>
          <Collapse in={!show} timeout={50}>
            <ServicioForm
              data={selectedData}
              editar={editar}
              setShow={setShow}
              getLista={getData}
              tipos={tipoServicios}
              departamentos={departamentos}
              responsables={responsables}
              unidadesArr={unidadesInsumos}
              triggerEdit={triggerEdit}
              setTriggerEdit={setTriggerEdit}
            />
          </Collapse>
        </Fade>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Servicio;