import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPDF, { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { Divider } from '@mui/material';
import { getDateNowDDMMYYYY, parseCurrency } from '../../tools/utils';

function PDFReporteExistencia({ dataReporte, costoTotal }) {

    const styles = StyleSheet.create({
        page: {
            fontFamily: 'Helvetica',
            fontSize: 7,
            paddingTop: 0,
            paddingLeft: 60,
            paddingRight: 60,
            lineHeight: 1.1,
            flexDirection: 'column',
            display: "flex",
        },
        table: {
            width: "97%",
            height: "84%",
            display: "flex",
            borderColor: "#000000",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 30,
            fontSize: 9,
            paddingTop: 0,
        },

        row: {
            width: "100%",
            borderColor: "#000000",
            flexDirection: 'row',
        },

        col: {
            borderColor: "#000000",
            flex: 1,
            paddingTop: 0,
        },

        borderX: {
            borderRight: 1,
            borderLeft: 1,
        },

        borderE: {
            borderRight: 1,
        },

        borderT: {
            borderTop: 1,
        },

        borderB: {
            borderBottom: 1,
        },

        center: {
            verticalAlign: "middle",
            marginTop: "auto",
            marginBottom: "auto",
            paddingLeft: 10,
            paddingRight: 8,
            paddingTop: 4,
            paddingBottom: 4,
        },

        textCenter: {
            textAlign: "center",
        },

        textLeft: {
            textAlign: 'left'
        },

        textRight: {
            textAlign: 'right'
        },

        textUpperCase: {
            textTransform: 'uppercase'
        },

        topSection: {
            marginTop: 10
        },

        flex_5: {
            flex: .5,
        },

        flex_1: {
            flex: 1,
        },

        flex15: {
            flex: 1.5,
        },

        head: {
            fontWeight: "heavy",
            backgroundColor: "#dedede",
            fontFamily: 'Helvetica-Bold'
        },

        textSmall: {
            fontSize: 8,
        },

        pagina: {
            position: "fixed",
            bottom: 15,
            left: 0,
            paddingRight: 28,
            textAlign: "right",
            fontSize: 7,
        },

        w25: {
            width: "30%"
        },

        w20: {
            width: "35%"
        },

        w35: {
            width: "35%"
        },

    });
 
    const pageExistencia = (init, end) => (
        <Page size="A4" wrap style={styles.page} scale="1.5">

            <View style={styles.table}>

                <View style={[styles.borderX, styles.borderT]}>
                    <View style={[styles.row, styles.borderB, styles.head]}>
                        <View style={styles.col}>
                            <Text style={[styles.center, styles.textCenter]}>REPORTE DE EXISTENCIA DE INSUMOS</Text>
                        </View>
                    </View>
                </View>

                <Divider style={[styles.topSection]} />

                <View style={[styles.row]}>
                    <View style={[styles.col, styles.w25]} >
                        <Text style={[styles.center, styles.textLeft, ]}>FECHA: { getDateNowDDMMYYYY() } </Text>
                    </View>
                    <View  style={[styles.col, styles.w20]} >
                        <Text style={[styles.center, styles.textCenter, ]}>&nbsp;</Text>
                    </View>                    
                    <View style={[styles.col, styles.w35]} >
                        <Text style={[styles.center, styles.textRight, ]}>COSTO TOTAL: { parseCurrency(costoTotal) } </Text>
                    </View>
                </View>

                <Divider style={[styles.topSection]} />

                <View style={[styles.borderX]}>
                    <View style={[styles.row, styles.borderB, styles.borderT]}>

                        <View style={[styles.col, styles.borderE, styles.head, styles.flex_5,]}>
                            <Text style={[styles.center, styles.textCenter]}>CLAVE</Text>
                        </View>
                        <View style={[styles.col, styles.borderE, styles.head, styles.flex15]}>
                            <Text style={[styles.center, styles.textCenter]}>NOMBRE</Text>
                        </View>
                        <View style={[styles.col, styles.borderE, styles.head]}>
                            <Text style={[styles.center, styles.textCenter]}>UNIDAD</Text>
                        </View>
                        <View style={[styles.col, styles.borderE, styles.head]}>
                            <Text style={[styles.center, styles.textCenter]}>ÚLTIMO PRECIO UNITARIO</Text>
                        </View>
                        <View style={[styles.col, styles.head, styles.flex_1]}>
                            <Text style={[styles.center, styles.textCenter]}>EXISTENCIA</Text>
                        </View>
                    </View>
                    {
                        dataReporte.slice(init, end).map((item, index) => (

                            <View key={item.id_insumo} style={[styles.row, styles.borderT, styles.borderB]}>

                                <View style={[styles.col, styles.borderE, styles.flex_5,]}>
                                    <Text style={[styles.center, styles.textLeft, styles.textUpperCase, styles.textSmall]}>{item.clave}</Text>
                                </View>

                                <View style={[styles.col, styles.borderE, styles.flex15]}>
                                    <Text style={[styles.center, styles.textLeft, styles.textUpperCase, styles.textSmall]}>{item.nombre}</Text>
                                </View>

                                <View style={[styles.col, styles.borderE]}>
                                    <Text style={[styles.center, styles.textCenter, styles.textUpperCase, styles.textSmall]}>{item.nombreUnidadMedida}</Text>
                                </View>

                                <View style={[styles.col, styles.borderE]}>
                                    <Text style={[styles.center, styles.textRight, styles.textUpperCase, styles.textSmall]}>{ parseCurrency(item.ultimo_precio)}</Text>
                                </View>

                                <View style={[styles.col, styles.borderE, styles.flex_1]}>
                                    <Text style={[styles.center, styles.textCenter, styles.textUpperCase, styles.textSmall]}>{item.existencia}</Text>
                                </View>

                            </View>
                        ))
                    }
                </View>
            </View>
        </Page>
    )

    return (
        <Document>
            {
                pageExistencia()
            }
        </Document>
    );
}

PDFReporteExistencia.propTypes = {
    dataReporte: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array])).isRequired,
    costoTotal: PropTypes.number.isRequired,
};

export default PDFReporteExistencia;