import React, {useMemo} from 'react';
import { Outlet , Navigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { USER_INSTRUCTOR, USER_LIDER_CAPACITACION, USER_LIDER_SIPA, USER_COTIZACIONES } from 'constans';

import SignIn from "layouts/authentication/sign-in";

 
function RouteGuard2(){
   // check user has JWT token
   let routeCustom = '/mis-oss';
   const {user} = JSON.parse(localStorage.getItem("user"));
   if(localStorage.getItem("user")){
      if(user.admin === 1){
      	routeCustom = '/captura-oss';
      }else if(user.tipo === 'cursante' || user.tipo === 'cursante-cliente'){
         routeCustom = '/cursantes-externo';
      }else if(user.tipo === 'cliente'){
         routeCustom = '/catalogos-certificado/cursantes';
      }else if(user.admin === USER_INSTRUCTOR){
         routeCustom = '/cursos/programacion-cursos';
      }else if(user.admin === USER_LIDER_CAPACITACION){
         routeCustom = '/cursos/programacion-cursos';
      }else if(user.admin === USER_LIDER_SIPA){
         routeCustom = '/cotizacion/captura-cotizacion';
      }else if(user.admin === USER_COTIZACIONES){
         routeCustom = '/cotizacion/captura-cotizacion';
      }
      return <Navigate to={routeCustom} />
   }
   return <SignIn />
};

export default RouteGuard2;