import React from 'react';
import PropTypes from 'prop-types';
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

function HistoryGraphInsumo({ dataChart }) {

    return (
       
        <div>     
             { dataChart ?                
                <DefaultLineChart
                    chart={{
                        labels: dataChart.labels,
                        datasets: [
                            {
                                label: dataChart.label,
                                color: dataChart.color,
                                data: (dataChart.values) ? dataChart.values : [],
                            }
                        ],
                    }}
                />
            : <div>No existe informacion</div>
            }
        </div>
      
    );

};

HistoryGraphInsumo.propTypes = {
    dataChart: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
};

export default HistoryGraphInsumo;
