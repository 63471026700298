
import React, { useState, useEffect, useRef } from "react";

import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import UnidadesMedidas from "layouts/catalogo/UnidadesMedidas";
import Proveedores from "layouts/catalogo/Proveedores";
import Departamentos from "layouts/catalogo/Departamentos";
import Insumos from "layouts/catalogo/Insumos";

function Administracion() {

    const navigate = useNavigate();
    // const scrollRef = useRef(React.createRef());
    const [valueTab, setValueTab] = useState('0');

    useEffect(() => {
    }, []);


    return (
        <DashboardLayout>
            <MDBox pb={3} >
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox pt={3}>
                                <MDBox pb={3} px={3}>
                                    <MDBox>
                                        <Grid pb={3}>
                                            <Tabs
                                                value={valueTab}
                                                textColor="primary"
                                                indicatorColor="primary"
                                                onChange={(event, newValue) => {
                                                    setValueTab(newValue);
                                                }}
                                            >
                                                <Tab label="Insumos" value="0" />
                                                <Tab label="Proveedores" value="1" />
                                                <Tab label="Unidades" value="2" />
                                                <Tab label="Departamentos" value="3" />
                                            </Tabs>

                                            <Grid md={12} pt={3} pb={3} spacing={6}>
                                                {valueTab === "0" ? <Insumos /> : null}
                                                {valueTab === "1" ? <Proveedores /> : null}
                                                {valueTab === "2" ? <UnidadesMedidas /> : null}
                                                {valueTab === "3" ? <Departamentos /> : null}
                                            </Grid>

                                        </Grid>

                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout >
    );
}

Administracion.propTypes = {
};

export default Administracion;
