/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Modal from 'react-modal';

import Card from "@mui/material/Card";
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDProgress from "components/MDProgress";

import MDButton from "components/MDButton";
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import Icon from "@mui/material/Icon";
import moment from 'moment';

import { Get, Del } from "../../tools/Helper";
import DistribucionInsumoDeptoForm from "./distribucionInsumoDeptoForm";
import { getDateNow, parseCurrency } from '../../tools/utils';

// TODO: 
// _ Paginacion
// _ Validar si el elemento a eliminar ya se está usando

function DistribucionInsumoDepto() {

  const emptyForm = {
    fecha: getDateNow(),
    clave: '',
    cantidad: '',
    id_insumo: '',
    id_departamento: '',
    insumo: '',
    departamento: '',
    unidad_medida: '',
  };

  const [show, setShow] = useState(true);
  const [showDetail, setShowDetail] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const isMountedRef = useRef(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState({ id: 0, empresa: "" });
  const [insumos, setInsumos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);

  const getData = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Get(`asignacion-insumo-depto`, isMountedRef.current);

    if (request.status === "success") {
      setData(request.data);      
    } else if (request.status === "empty") {
      setData([]);
      setStatus({ status: request.status, message: "Información cargada exitosamente." });
    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setInsumos(request.insumos);      
    setDepartamentos(request.departamentos);    

    setSelectedData(emptyForm);
    setLoading(false);

  }

  useEffect(() => {

    getData();

  }, []);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete(null);
  }

  const crearButton = () => {
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
    setShowDetail(true);
  }

  const editarButton = (row) => {
    setSelectedData(row);
    setShow(false);
    setShowDetail(true);
    setEditar(true);
  }

  const deleteAsignacion = async () => {

    closeModal();
    setStatus({ status: null, message: "" });
    setLoading(true);

    console.log(selectedDelete);

    const request = await Del(`asignacion-insumo-depto/${selectedDelete.id_asign_insumo_depto}`);

    if (request.status === "success") {
      await getData();
    }

    setStatus(request);
    setShow(true);
    setLoading(false);
  }
  function openModal(row) {
    setIsOpen(true);
    setSelectedDelete(row);
  }

  const editButton = (row) => <Tooltip title="Editar"><MDButton className="btnPaymun" style={{ padding: "10px 4px", borderRadius: "0.5rem" }} iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton></Tooltip>;
  const deleteButton = (row) => <Tooltip title="Eliminar"><MDButton className="btnPaymun btnChangeStatus" style={{ padding: "10px 4px", borderRadius: "0.5rem" }} iconOnly onClick={() => openModal(row)}><Icon>deleteForever</Icon></MDButton></Tooltip>;
  const addButton = () => <MDButton className="btnPaymun btnAdd" onClick={() => crearButton()} style={{ padding: "10px 24px", borderRadius: "0.5rem" }} > <Icon fontSize="small">view_in_ar</Icon>&nbsp;Distribuir</MDButton>;

  const columns = [
    {
      name: 'Clave',
      selector: row => row.clave,
      width: "100px",

    },
    {
      name: 'Fecha',
      selector: row => moment(row.fecha).format('DD/MM/YYYY'),
      width: "120px",
      wrap: true,
    },
    {
      name: 'Insumo',
      selector: row => row.nombreInsumo,

    },
    {
      name: 'Cantidad',
      selector: row => row.cantidad,
      width: "100px",
    },
    {
      name: 'Departamento',
      selector: row => row.nombreDepto,
    },
    {
      name: '',
      selector: row => editButton(row),
      grow: 0,
      compact: true,
      width: "60px",
    },
    {
      name: '',
      selector: row => deleteButton(row),
      grow: 0,
      compact: true,
      width: "60px",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      maxWidth: '600px',
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Fade in={show} timeout={800}>
          <Collapse in={show && showDetail} timeout={50}>
            <Card>
              <MDBox
                p={3}
                className="headerFormPymn"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Distribución de insumo
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    {addButton()}
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? 
                  
                  <MDBox display="block" textAlign="center" p={5}>
                    <CircularProgress size="10rem" />
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                    :
                    <MDBox>
                      <DataTableExtensions
                        {...tableData}
                        export={false}
                        print={false}
                        filterPlaceholder="Buscar..."
                      >
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'Distribuciones por página:',
                            rangeSeparatorText: 'de',
                            noRowsPerPage: false,
                            selectAllRowsItem: true,
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                        />
                      </DataTableExtensions>
                    </MDBox>
                  }
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>

        <Fade in={!show} timeout={800}>
          <Collapse in={!show} timeout={50}>
            <DistribucionInsumoDeptoForm
              data={selectedData}
              editar={editar}
              setShow={setShow}
              setShowDetail={setShowDetail}
              getLista={getData}
              insumosArr={insumos}
              departamentosArr={departamentos}
            />
          </Collapse>
        </Fade>

      </MDBox>

      <Modal
        isOpen={modalIsOpen}
        contentLabel="¿Seguro que desea eliminar la asignación del insumo?"
        style={customStyles}
      >
        <Grid container p={1}>
          <Grid item md={12} sm={12}>
            <h2 className='alignCenter txtInfoModal' >¿Seguro que desea eliminar la asignación del insumo?</h2>
          </Grid>
        </Grid>

        <Grid container spacing={2} p={1}>
          <Grid item md={6} sm={12} xs={12} className='alignRigth' >
            <MDButton className="btnPaymun" disabled={loading}
              onClick={() => deleteAsignacion()} >
              <Icon fontSize="small">done</Icon>
              &nbsp;Eliminar
            </MDButton>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="alignLeft">
            <MDButton className="btnBackPaymun" disabled={loading}
              onClick={() => closeModal()} >
              <Icon fontSize="small">arrow_back</Icon>
              &nbsp;Cancelar
            </MDButton>
          </Grid>
        </Grid>

      </Modal>

      <Footer />

    </DashboardLayout>
  );
}

// Setting default props for the ProfileInfoCard
DistribucionInsumoDepto.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
DistribucionInsumoDepto.propTypes = {
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default DistribucionInsumoDepto;