/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import Modal from 'react-modal';
import { Post, Put, Del, Get } from "../../tools/Helper";
import Message from "../../tools/Message";

function UnidadesMedidasForm({ editar, data, setShow, getLista }) {

  const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });  

  const getClaveUnidadMedida = async e =>{
    
    if (!editar) {
      setStatus({status: null, message: ""});
      setLoading(true);
  
      const request = await Get('getClaveUnidadMedida');      
            
      if(request.status === "success"){   
        setFormData({ ...data, 'clave': (request.parametro.valor + request.total) });         
      }else{
        setStatus(request);
      }      
              
      setLoading(false);
    }
    
  }

  useEffect(() => {
    setFormData(data);
    setStatus({ status: null, message: "" });
    getClaveUnidadMedida();
  }, [data, setFormData]);

  const handleChange = (e, name = "", value = "") => {
    if (name === "") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [value]: e[value], [name]: e });
    }

    if ("errors" in status) {
      const verifyName = name === "" ? e.target.name : name;
      if (verifyName in status.errors) {
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const doRequest = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = editar ? await Put(`unidades-medidas/${formData.id_unidadmedida}`, formData) : await Post('unidades-medidas', formData);

    if (request.status === "success") {
      await getLista();
      setShow(true);      
    }
    else if (request.status === 'error'){
      setStatus(request);
    } 
    
    setLoading(false);

  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  return (
    <MDBox pb={3}>
      <Card mt={4}>
        <MDBox 
          p={3}          
          className="headerFormPymn"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                {editar ? "Actualizar" : "Agregar"} Unidad de medida
              </MDTypography>
            </Grid>           
          </Grid>
        </MDBox>

        <MDBox p={3} >
          <Message status={status} />
          <Grid container spacing={2}>
            {loading ?
              <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                <CircularProgress size="6.66rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </Grid>
              : <>
                <Grid item md={4} xs={12}>
                  <MDInput type="text" label="* Clave" name="clave"
                    error={verifyError("clave")} errorMsg={getError("clave")}
                    value={formData.clave} onChange={event => handleChange(event)}
                    inputProps={{ maxLength: 7, readOnly: true,}}
                    fullWidth />
                </Grid>
                <Grid item md={8} xs={12} mt="auto">
                  <MDInput type="text" label="* Nombre" name="nombre"
                    error={verifyError("nombre")} errorMsg={getError("nombre")}
                    inputProps={{ maxLength: 50, }}
                    value={formData.nombre} onChange={event => handleChange(event)} fullWidth />
                </Grid>

                <Grid item md={4} xs={12}>
                  &nbsp;
                </Grid>
                <Grid item md={2} xs={12}>
                  <MDButton disabled={loading}
                    className="btnPaymun"
                    onClick={() => doRequest()} fullWidth >
                    <Icon fontSize="small">done</Icon>
                    &nbsp;{editar ? "Actualizar" : "Agregar"}
                    
                  </MDButton>
                </Grid>

                <Grid item md={2} xs={12}>
                  <MDButton 
                    onClick={() => setShow(true)} 
                    className="btnBackPaymun" fullWidth>
                    <Icon fontSize="small">arrow_back</Icon>
                    &nbsp;Regresar
                  </MDButton>
                </Grid>
              </>
            }
          </Grid>
        </MDBox>
        
      </Card>
      
    </MDBox>
  );
}

UnidadesMedidasForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default UnidadesMedidasForm;