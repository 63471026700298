/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";


import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';

import {Post,Put,PostGuest} from "../../tools/Helper";
import Message from "../../tools/Message";

function ClienteForm({editar, data, setShow, getLista, isExternal = false}) {

	const [formData, setFormData] = useState(data);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(()=>{
    setFormData(data);
    setStatus({status: null, message: ""});
  },[data,setFormData]);

	const handleChange = (e) =>{
	    setFormData({...formData, [e.target.name]:e.target.value});
	}

  const doRequest = async e =>{
    
    setStatus({status: null, message: ""});
    setLoading(true);

    const tempData = formData;
    let request;
    if(editar){
      request =  await Put(`clientes/${formData.id_cliente}`,formData) 
    }else if(userData){
      request = await Post('clientes',formData)
    }else{
      request = await PostGuest('cliente-invitado',formData);
    }

    if(request.status === "success"){
      if(userData) { await getLista() }
      if(editar){
        setFormData(tempData);
      }
      setStatus(request);
      setLoading(false);
      return;
    }

    setStatus({ 
      ...request, 
      status: 'error'
    });
    setLoading(false);
  }

  const verifyError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return true;
      }
    }

    return false;
  }

  const getError = (name) =>{
    if("errors" in status){
      if(name in status.errors){
        return status.errors[name][0];
      }
    }

    return "";
  }

	return (
        <MDBox pb={3}>
          <Card mt={4}>
            <MDBox mx={2} mt={-3} py={3} px={2}
                variant="gradient" bgColor="info"
                borderRadius="lg" coloredShadow="info"
              >
              <Grid container spacing={6}>
                <Grid item xs={6}>
                <MDTypography variant="h4" display="inline" mr="auto" color="white">
                    Paso 1: Registro
                </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox p={3} >
              <Message status={status} />
              <Grid container spacing={2}>
                {loading ? 
                  <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                    <CircularProgress size="6.66rem" /> 
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </Grid>
                  : 
                <><Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Nombre Comercial" name="nombre_comercial" disabled={loading}
                      error={verifyError("nombre_comercial")} errorMsg={getError("nombre_comercial")}
                      value={formData.nombre_comercial} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                {/* <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Nombre y apellidos" name="nombre_patron" disabled={loading}
                      error={verifyError("nombre_patron")} errorMsg={getError("nombre_patron")}
                      value={formData.nombre_patron} onChange={event => handleChange(event)} fullWidth />
                </Grid> */}
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Telefono de contacto" name="telefono" disabled={loading}
                      error={verifyError("telefono")} errorMsg={getError("telefono")}
                      value={formData.telefono} onChange={event => handleChange(event)} fullWidth inputProps={{ maxLength: "10" }} />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDInput type="text" label="Correo" name="correo" disabled={loading}
                      error={verifyError("correo")} errorMsg={getError("correo")}
                      value={formData.correo} onChange={event => handleChange(event)} fullWidth />
                </Grid>
                <Grid item xs={6} mt="auto">
                  <MDButton variant="gradient" color="info" disabled={loading}
                      onClick={()=>doRequest()} fullWidth>
                      {editar ? "Actualizar" : "Agregar"}
                    </MDButton>
                </Grid></>}
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
	);
}

ClienteForm.propTypes = {
  editar: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  isExternal: PropTypes.bool.isRequired
};

export default ClienteForm;