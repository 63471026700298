/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import ReactPDF , { pdf, Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image  } from '@react-pdf/renderer';


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDProgress from "components/MDProgress";

import MDButton from "components/MDButton";
import DataTable from 'react-data-table-component';
import Icon from "@mui/material/Icon";

import { Get } from "../../tools/Helper";
import { parseCurrency, getDateNowWithoutSeparator, getRandomString } from '../../tools/utils';
import PDFReporteExistencia from "./PDFReporteExistencia";

function ReporteExistenciaInventario() {

  const [status, setStatus] = useState({ status: null, message: "" });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [costoTotal, setCostoTotal] = useState(0);

  const getData = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Get(`getInfoReportExistences`);

    if (request.status === "success") {
      setData(request.data);
      setCostoTotal(request.costoTotal);
      setStatus({ status: request.status, message: "Información cargada exitosamente." });
    } else if (request.status === "empty") {
      setData([]);
      setStatus({ status: request.status, message: "Información cargada exitosamente." });
    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);

  }

  useEffect(() => {

    getData();

  }, []);

  const columns = [
    {
      name: 'CLAVE',
      selector: row => row.clave,
      width: "120px",

    },
    {
      name: 'NOMBRE',
      selector: row => row.nombre,
    },
    {
      name: 'UNIDAD',
      selector: row => row.nombreUnidadMedida,
      wrap: true,
      width: "250px",
    },
    {
      name: 'ÚLTIMO PRECIO UNITARIO',
      selector: row => parseCurrency(row.ultimo_precio),
      width: "180px",
    },
    {
      name: 'EXISTENCIA',
      selector: row => row.existencia,
      grow: 0,
      compact: true,
      width: "100px",
    },
  ];


  const exportReportExistence = async(row) => {
  
    const blob = await pdf(
      <PDFReporteExistencia dataReporte = {data} costoTotal = {costoTotal} />
    ).toBlob();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `reporte_existencia_${getDateNowWithoutSeparator()}_${getRandomString(5)}.pdf`;
    link.click();
  }

  const exportReport = () => <MDButton className="btnPaymun btnAdd" onClick={() => exportReportExistence()} style={{ padding: "10px 24px", borderRadius: "0.5rem" }} > <Icon fontSize="small">picture_as_pdf</Icon>&nbsp;Exportar</MDButton>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Card>
          <MDBox
            p={3}
            className="headerFormPymn"
          >
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <MDTypography variant="h4" display="inline" mr="auto" color="white">
                  Reporte de existencia de insumos
                </MDTypography>
              </Grid>
              <Grid item xs={6} textAlign="right" >
                {exportReport()}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={3}>
            <MDBox px={3}>
              {loading ? <MDBox display="block" textAlign="center" p={5}>
                <CircularProgress size="10rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </MDBox>
                :
                <MDBox>

                  <DataTable
                    columns={columns}
                    data={data}
                    noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                  />

                </MDBox>
              }
            </MDBox>
          </MDBox>
        </Card>


      </MDBox>
      <Footer />

    </DashboardLayout>
  );

}
// Typechecking props for the ProfileInfoCard
ReporteExistenciaInventario.propTypes = {
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,

};

export default ReporteExistenciaInventario;