/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Modal from 'react-modal';

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import Card from "@mui/material/Card";
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from "@mui/material/CircularProgress";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDProgress from "components/MDProgress";

import MDButton from "components/MDButton";
import DataTableExtensions from 'react-data-table-component-extensions';
import DataTable from 'react-data-table-component';
import Icon from "@mui/material/Icon";
import moment from 'moment';

import { Get, Del } from "../../tools/Helper";
import InventarioForm from "./inventarioForm";
import DetailInsumoInventario from "./detailInsumoInventario";
import { getDateNow, parseCurrency } from '../../tools/utils';
import Message from "../../tools/Message";

import HistoryGraphInsumo from "./historyGraphInsumo";
// TODO: 
// _ Paginacion
// _ Validar si el elemento a eliminar ya se está usando

function Inventario() {

  const emptyForm = {
    fecha: getDateNow(),
    insumos: '',
    proveedores: '',
    clave: '',
    cantidad: '',
    precio: '',
  };

  const [show, setShow] = useState(true);
  const [showDetail, setShowDetail] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [movInventario, setMovInventario] = useState(emptyForm);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [movimientos, setMovimientos] = useState([]);
  const [ultimoPrecio, setUltimoPrecio] = useState(0);
  const [existencia, setExistencia] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const isMountedRef = useRef(false);
  const scrollRef = useRef(React.createRef());
  const [formData, setFormData] = useState(data);
  const [valueTab, setValueTab] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState({ id: 0, empresa: "" });
  const [historialPreciosInsumo, setHistorialPreciosInsumo] = useState({ "label": 'Ingreso', "color": 'info', "labels": [], "values": [] });
  const [historialIngresosInsumo, setHistorialIngresosInsumo] = useState([]);

  // let mounted = true;
  let isSubscribed = true;

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const crearButton = () => {

    setMovInventario(emptyForm);
    setShow(false);
    setEditar(false);
    setShowDetail(true);
  }

  const getData = async e => {

    isMountedRef.current = true
    setIsMounted(true);

    const request = await Get(`inventario`, isMountedRef.current);
    
    setStatus({ status: null, message: "" });
    setLoading(true);

    if (request.status === "success") {
      setData(request.data);
      setStatus({ status: request.status, message: "Información cargada exitosamente." });
    } else if (request.status === "empty") {
      setData([]);
      setStatus({ status: request.status, message: "Información cargada exitosamente." });
    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setMovInventario(emptyForm);
    setLoading(false);

  }

  useEffect(() => {

    isMountedRef.current = true
    setIsMounted(true);
    isSubscribed = true;

    getData();

    return () => {
      isMountedRef.current = false
      setIsMounted(false); // Set the flag to false when the component unmounts
    }

  }, []);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete(null);
  }

  const mostrarInventario = () => {
    // getLista();
    setShowDetail(true);
  }

  const getMovimientos = async (idInsumo) => {

    if (idInsumo !== 0 && idInsumo !== undefined) {
      setStatus({ status: null, message: "" });
      setLoading(true);

      const request = await Get(`getMovimientosInsumo/${idInsumo}`);

      if (request.status === "success") {
        setUltimoPrecio(request.ultimo_precio);
        setExistencia(request.existencia);                    
        setMovimientos(request.data);

        const fechasPrecios = [];
        const fechasIngresos = [];
        const precios = [];

        request.infoHistorialInsumo.fechasPrecios.forEach(item => {
          fechasPrecios.push(moment(item).format('DD/MM'));
        });

        request.infoHistorialInsumo.precios.forEach(item => {
          precios.push(item);
        });

        request.infoHistorialInsumo.fechasIngresos.forEach(item => {
          fechasIngresos.push(moment(item).format('DD/MM'));
        });

        setHistorialPreciosInsumo({ "label": 'Precio', "color": 'info', "labels": fechasPrecios, "values": precios });
        setHistorialIngresosInsumo({ "label": 'Ingreso', "color": 'success', "labels": fechasIngresos, "values": request.infoHistorialInsumo.ingresos });

      }
      else if (request.status === "empty") {

        getData();
        setLoading(false);
        setShowDetail(true);

      }
      else {
        setStatus(request);
      }

      setLoading(false);
    }
  }

  const getDetail = (row) => {
    getMovimientos(row.id_insumo);
    setSelectedData(row);
    setFormData(row)
    setShowDetail(false);
  };

  const showExistenciaProgress = row => {

    const total = parseInt(row.total, 10);
    const cantCritica = parseInt(row.cantidad_critica, 10);
    let classNameStatus = '';

    if (total >= (cantCritica + 10)) {
      classNameStatus = 'ok';
    }

    if (total < (cantCritica + 10) &&
      total > cantCritica) {
      classNameStatus = 'regular';
    }

    if (total <= cantCritica) {
      classNameStatus = 'danger';
    }

    return <Tooltip title={`Cant. crítica: ${cantCritica}`}><span className={`badge badge-lg badge-dot badge-dot-${classNameStatus}`}><i>&nbsp;</i>{`${row.total}`} </span></Tooltip>;

  };

  const editarButton = (row) => {
    setMovInventario(row);
    setShow(false);
    setShowDetail(true);
    setEditar(true);
  }

  function openModal(row) {    
    setIsOpen(true);
    setSelectedDelete(row);
  }

  const detailButton = (row) => <Tooltip title="Detalle"><MDButton className="btnPaymun primary" style={{ padding: "10px 4px", borderRadius: "0.5rem" }} iconOnly onClick={() => getDetail(row)}><Icon>search</Icon></MDButton></Tooltip>;
  const editButton = (row) => <Tooltip title="Editar"><MDButton className="btnPaymun" style={{ padding: "10px 4px", borderRadius: "0.5rem" }} iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton></Tooltip>;
  const deleteButton = (row) => <Tooltip title="Eliminar"><MDButton className="btnPaymun btnChangeStatus" style={{ padding: "10px 4px", borderRadius: "0.5rem" }} iconOnly onClick={() => openModal(row)}><Icon>deleteForever</Icon></MDButton></Tooltip>;
  const addButton = () => <MDButton className="btnPaymun btnAdd" onClick={() => crearButton()} style={{ padding: "10px 24px", borderRadius: "0.5rem" }} > <Icon fontSize="small">add</Icon>&nbsp;Agregar</MDButton>;

  const columns = [
    {
      name: 'Clave',
      selector: row => row.clave,
      width: "100px",

    },
    {
      name: 'Descripción',
      selector: row => row.nombre,
      wrap: true,
    },
    {
      name: 'Unidad',
      selector: row => row.nombreUnidadMedida,

    },
    {
      name: 'Existencia',
      selector: row => showExistenciaProgress(row),
    },
    {
      name: '',
      selector: row => detailButton(row),
      grow: 0,
      compact: true,
      width: "60px",
    }
  ];

  const tableData = {
    columns,
    data,
  };

  const deleteInventario = async () => {

    closeModal();
    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Del(`inventario/${selectedDelete.id_inventario}`);

    if (request.status === "success") {
      await getMovimientos(selectedDelete.id_insumo);
    }

    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  const columnsDetail = [
    {
      name: 'Fecha',
      selector: row => moment(row.fecha).format('DD/MM/YYYY'),
      width: "120px",

    },
    {
      name: 'Cantidad',
      selector: row => row.cantidad,
      width: "90px",
    },
    {
      name: 'Proveedor',
      selector: row => row.proveedores.empresa,
      wrap: true,
      grow: 1,
    },
    {
      name: 'Precio',
      selector: row => parseCurrency(row.precio),
    },
    {
      name: '',
      selector: row => editButton(row),
      grow: 0,
      compact: true,
      width: "60px",
    },
    {
      name: '',
      selector: row => deleteButton(row),
      grow: 0,
      compact: true,
      width: "60px",
    },
  ];

  const tableDataDetail = {
    columns,
    data,
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Fade in={show} timeout={800}>
          <Collapse in={show && showDetail} timeout={50}>
            <Card>
              <MDBox
                p={3}
                className="headerFormPymn"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Inventario
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    {addButton()}
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" textAlign="center" p={5}>
                    <CircularProgress size="10rem" />
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                    :
                    <MDBox>
                      <DataTableExtensions
                        {...tableData}
                        export={false}
                        print={false}
                        filterPlaceholder="Buscar..."
                      >
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: 'Insumos por página:',
                            rangeSeparatorText: 'de',
                            noRowsPerPage: false,
                            selectAllRowsItem: true,
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                        />
                      </DataTableExtensions>
                    </MDBox>
                  }
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>

        <Fade in={!show} timeout={800}>
          <Collapse in={!show} timeout={50}>
            <InventarioForm
              data={movInventario}
              editar={editar}
              setShow={setShow}
              setShowDetail={setShowDetail}
              getLista={getData}
              getMovimientos={getMovimientos}
            />
          </Collapse>
        </Fade>

        <Fade in={!showDetail} timeout={800}>
          <Collapse in={!showDetail} timeout={50}>
            <MDBox pb={3} ref={scrollRef}>

              <Card mt={4}>
                <MDBox
                  p={3}
                  className="headerFormPymn"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <MDTypography variant="h4" display="inline" mr="auto" color="white">
                        Detalle del Insumo en Inventario
                      </MDTypography>
                    </Grid>                    
                  </Grid>
                </MDBox>

                <MDBox p={3} >
                  <Message status={status} />
                  <Grid container spacing={2} padding={2}>
                    {loading ?
                      <Grid item xs={12} display="block" fullWidth textAlign="center" p={5} mt={4}>
                        <CircularProgress size="6.66rem" />
                        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                      </Grid>
                      : <>

                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4} sm={6}>
                            <MDTypography variant="string" fontSize="16" fontWeight="medium">
                              Clave:&nbsp;
                            </MDTypography>
                            <MDTypography fontSize="16" variant="string">
                              {(formData !== undefined) ? formData.clave : ''}
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12} md={4} sm={6}>
                            <MDTypography fontSize="16" variant="string" fontWeight="medium">
                              Nombre:&nbsp;
                            </MDTypography>
                            <MDTypography fontSize="16" variant="string">
                              {formData.nombre}
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12} md={4} sm={6}>
                            <MDTypography fontSize="16" variant="string" fontWeight="medium">
                              Unidad:&nbsp;
                            </MDTypography>
                            <MDTypography fontSize="16" variant="string">
                              {formData.nombreUnidadMedida}
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12} md={4} sm={6}>
                            <MDTypography fontSize="16" variant="string" fontWeight="medium">
                              Cantidad crítica en stock:&nbsp;
                            </MDTypography>
                            <MDTypography fontSize="16" variant="string">
                              {formData.cantidad_critica}
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12} md={4} sm={6}>
                            <MDTypography fontSize="16" variant="string" fontWeight="medium">
                              Existencia en reserva:&nbsp;
                            </MDTypography>
                            <MDTypography fontSize="16" variant="string">
                              {existencia}
                            </MDTypography>
                          </Grid>

                          <Grid item xs={12} md={4} sm={6}>
                            <MDTypography fontSize="16" variant="string" fontWeight="medium">
                              Último precio de compra:&nbsp;
                            </MDTypography>
                            <MDTypography fontSize="16" variant="string">
                              {parseCurrency(ultimoPrecio)}
                            </MDTypography>
                          </Grid>
                        </Grid>

                        <Divider className="fullWidth" />

                        <Grid container spacing={2}>

                          <Grid item md={6} sm={12}>
                            <Grid item xs={12} md={12} sm={12}>
                              <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                Movimientos
                              </MDTypography>
                            </Grid>

                            <Grid item xs={12} md={12} sm={12}>
                              <DataTable
                                columns={columnsDetail}
                                data={movimientos}
                                pagination
                                paginationComponentOptions={{
                                  rowsPerPageText: 'Insumos por página:',
                                  rangeSeparatorText: 'de',
                                  noRowsPerPage: false,
                                  selectAllRowsItem: true,
                                  selectAllRowsItemText: 'Todos'
                                }}
                                noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                              />
                            </Grid>
                          </Grid>

                          <Grid item md={6} sm={12}>

                            <Grid item xs={12} md={12} sm={12}>
                              <MDTypography variant="string" fontSize="16" fontWeight="medium">
                                Historial
                              </MDTypography>
                            </Grid>

                            <Grid item xs={12} md={12} sm={12} >

                              <Tabs
                                value={valueTab}
                                centered
                                textColor="primary"
                                indicatorColor="primary"
                                onChange={(event, newValue) => {
                                  setValueTab(newValue);
                                }}
                              >
                                <Tab label="Precio" />
                                <Tab label="Ingresos" />
                              </Tabs>

                              <Grid >
                                {valueTab === 0 && historialPreciosInsumo !== undefined ?
                                  <HistoryGraphInsumo dataChart={historialPreciosInsumo} />
                                  : null
                                }
                                {valueTab === 1 ?
                                  <HistoryGraphInsumo dataChart={historialIngresosInsumo} />
                                  : null}
                              </Grid>
                            </Grid>

                          </Grid>

                        </Grid>

                        <Grid container className="alignCenter">
                          <Grid item md={2} xs={12}>
                            <MDButton
                              onClick={() => mostrarInventario()}
                              className="btnBackPaymun"
                              fullWidth
                              style={{ padding: "10px 24px !important", borderRadius: "0.5rem" }}>
                              <Icon fontSize="small"
                              >arrow_back</Icon>
                              &nbsp;Regresar
                            </MDButton>
                          </Grid>
                        </Grid>
                      </>
                    }
                  </Grid>
                </MDBox>

              </Card>
            </MDBox>

            <Modal
              isOpen={modalIsOpen}
              contentLabel={`¿Seguro que desea eliminar el ingreso del Insumo ${(selectedDelete ? formData.nombre : '')}?`}
              style={customStyles}
            >
              <Grid container p={1}>
                <Grid item md={12} sm={12}>
                  <h2 className='alignCenter txtInfoModal' >{`¿Seguro que desea eliminar el ingreso del insumo ${(selectedDelete ? formData.nombre : '')}?`}</h2>
                </Grid>
              </Grid>

              <Grid container spacing={2} p={1}>
                <Grid item md={6} sm={12} xs={12} className='alignRigth' >
                  <MDButton className="btnPaymun" disabled={loading}
                    onClick={() => deleteInventario()} 
                    style={{ padding: "10px 24px !important", borderRadius: "0.5rem" }}>
                    <Icon fontSize="small">done</Icon>
                    &nbsp;Eliminar
                  </MDButton>
                </Grid>
                <Grid item md={6} sm={12} xs={12} className="alignLeft">
                  <MDButton className="btnBackPaymun" disabled={loading}
                    onClick={() => closeModal()} 
                    style={{ padding: "10px 24px !important", borderRadius: "0.5rem" }}>
                    <Icon fontSize="small">arrow_back</Icon>
                    &nbsp;Cancelar
                  </MDButton>
                </Grid>
              </Grid>

            </Modal>
          </Collapse>
        </Fade>
    
      </MDBox>

      <Footer />

    </DashboardLayout>
  );
}

// Setting default props for the ProfileInfoCard
Inventario.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
Inventario.propTypes = {
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default Inventario;